export const courses = [
    {
        logo: '/imgs/skills/skills1.png',
        graduate: true,
        title: 'Full Stack Developer Bootcamp',
        where: 'Digital House'
    },
    {
        logo: '/imgs/skills/skills1.png',
        graduate: false,
        title: 'Ethereum Blockchain Developer (Solidity)',
        where: 'Udemy'
    },
    {
        logo: '/imgs/skills/skills1.png',
        graduate: true,
        title: 'Cybersecurity Fundamentals',
        where: 'CoderHouse'
    },
    {
        logo: '/imgs/skills/skills4.png',
        graduate: true,
        title: 'B.A. Filmscoring & Music Production',
        where: 'Udemy'
    },
    {
        logo: '/imgs/skills/skills1.png',
        graduate: false,
        title: 'Python For Everybody',
        where: 'Coursera'
    },
    {
        logo: '/imgs/skills/skills1.png',
        graduate: false,
        title: 'IT Fundamentals',
        where: 'Coursera'
    },
    {
        logo: '/imgs/skills/skills1.png',
        graduate: false,
        title: 'Javascript Algorithms and Data Structures',
        where: 'freeCodeCamp'
    },
    {
        logo: '/imgs/skills/skills1.png',
        graduate: false,
        title: 'Responsive Web Design',
        where: 'freeCodeCamp'
    },
    {
        logo: '/imgs/skills/skills1.png',
        graduate: false,
        title: 'Yoga instructor',
        where: 'Instituto Internacional de Yoga'
    },
]