import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideRomanPopup } from '../../redux/romanPopupSlice';

const RomanNumerals = () => {
  const [number, setNumber] = useState(0);
  const [res, setRes] = useState('');

//   redux
    const dispatch = useDispatch();
    
    const handleClose = () => {
        dispatch(hideRomanPopup());
    };

  function convertToRoman(num) {
    if(num > 4000 || num < 1){
      return "Yikes, Romans didn't use numbers greater than 4000 (facts!)";
    }
    
    let map = {M:1000, CM:900, D:500, CD:400, C:100, XC:90, L:50, XL:40, X:10, IX:9, V:5, IV:4, I:1};
    let output = '';
    
    for (let i in map ) {
      while ( num >= map[i] ) {
        output += i;
        num -= map[i];
      }
    }
    
    return output;
  }

  const setNum = (event) => {
    setNumber(event.target.value);
  }

  const handleRoman = () => {
    const romanNumeral = convertToRoman(number);
    setRes(romanNumeral);
  }

  return (
    <div className='roman-wrapper'>
        <button className='roman-close-button' onClick={handleClose}>Close</button>
        <h3>Roman Numeral Converter</h3>
      <input type="number" value={number} onChange={setNum} max={4000} />
      <button onClick={handleRoman}>Convert</button>
      {res.length > 0 ? <p style={{margin:0}}>{res}</p> : null}
    </div>
  );
}

export default RomanNumerals;
