import React, { useState, useEffect } from "react";
import Card from "../components/Card/Card";
import Skill from "../components/Skill/Skill";
import Course from "../components/Course/Course";
import Social from "../components/Social/Social";
import RomanNumerals from "../components/RomanNumerals/RomanNumerals";
import Language from "../components/Language/Language";
import Footer from "../components/Footer/Footer";

import { skills } from "../data/skills";
import { courses } from "../data/courses";
import { projects } from "../data/projects";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { showRomanPopup } from "../redux/romanPopupSlice";
import { languages } from "../data/languages";

const Home = () => {
  const [showDescription, setShowDescription] = useState(false);

  //   redux
  const dispatch = useDispatch();
  const isRomanPopupOpen = useSelector((state) => state.romanPopup.value);

  const handleOpen = () => {
    dispatch(showRomanPopup());
  };

  // Pop up for music
  const [countdown, setCountdown] = useState(null);
  const [musicPopUp, setMusicPopUp] = useState(false);

  const handleClick = (e) => {
    e.preventDefault(); // Prevent the link from being followed immediately
    setMusicPopUp(true);
    setCountdown(1);
  };

  const handleShowDescription = () => {
    console.log("show description");
    setShowDescription(!showDescription);
  };

  useEffect(() => {
    let countdownInterval = null;
    if (countdown > 0) {
      countdownInterval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      window.location.href =
        "https://youtu.be/k4BhPTI6IYs?list=PLgUK4IyWLeDu_lWTuaxpCJFUIv7YVQF_j";
    }
    return () => clearInterval(countdownInterval); // Clean up on unmount
  }, [countdown]);

  return (
    <div className="home_wrapper">
      <header>
        <a className="header-logo" href="/">
          <h1>SVB</h1>
        </a>
        <div className="header_container">
          <div className="buttons">
            <a style={{ color: "white" }} href="#aboutme">
              <button id="About">
                <span></span>About Me
              </button>
            </a>
            <a href="#projectsAncher">
              <button id="projects">
                <span></span>Projects
              </button>
            </a>
            <a
              onClick={handleClick}
              href="/https://youtu.be/k4BhPTI6IYs?list=PLgUK4IyWLeDu_lWTuaxpCJFUIv7YVQF_j"
              target="_blank"
            >
              <button>
                <span></span>Music
              </button>
            </a>
          </div>

          {musicPopUp ? (
            <div className="music-popup">
              <p>
                You'll see a Youtube playlist with some of my work in a second
              </p>
            </div>
          ) : null}
        </div>

        <div className="navbar__toggle" id="hamb_menu">
          <span className="bar"></span> <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </header>

      <main style={{ overflow: "hidden" }}>
        <div className="banner">
          <div className="banner_text">
            <span className="multicolor">
              <h1>SANTIAGO VB</h1>
            </span>
            <p>@blockchain.addict</p>
            <h3>Full Stack Developer & Music Producer</h3>
          </div>
          <p className="banner_quote">
            <span id="span_animation"></span>
          </p>

          {/* imgs */}
          <img
            className="bimg b-logo-1"
            src="/imgs/banner_logos/react-logo.png"
            alt=""
          />
          <img
            className="bimg b-logo-2"
            src="/imgs/banner_logos/solidity.png"
            alt=""
          />
          <img
            className="bimg b-logo-3"
            src="/imgs/banner_logos/html.png"
            alt=""
          />
          <img
            className="bimg b-logo-4"
            src="/imgs/banner_logos/css.png"
            alt=""
          />

          <div className="sphere"></div>
        </div>

        <div
          className="about-section"
          style={{ position: "relative", zIndex: 0 }}
        >
          <div className="pic_wrapper">
            <img id="mypic" src="imgs/prof_copy.jpg" alt="img" />

            {/* deco elements */}
            <div className="square-behind-pic"></div>
          </div>
          <div className="about_wrapper">
            <span id="span2"></span>
            <h1 id="aboutme">About Me</h1>
            <p>
              Hey there! I'm a Full Stack Web Dev specialized in
              React, Node.JS and Blockchain.{" "}
            </p>

            <p>
              Currently working as a Frontend Developer at Brandtrack, where
              music meets tech, and I also get to teach the next wave of
              developers at Develhope. Before diving into the tech world, I cut
              my teeth in advertising, which really helps me think about how
              users interact with websites.{" "}
            </p>

            <p>
              I’m all about facing new challenges head-on, figuring out puzzles,
              and learning something new every day. This career keeps me on my
              toes, and I wouldn’t have it any other way. I’m a quick learner, a
              science geek, and a sports enthusiast.{" "}
            </p>

            <p>
              I’ve juggled a ton of projects, from leading teams to smashing
              freelance gigs. Driven, sharp, and always on the lookout for that
              spark of creativity—I love pushing boundaries and exploring what’s
              next in the tech scene.
            </p>
          </div>

          {/* Decoration */}
          <div className="gray-element1"></div>
          <div className="gray-element2"></div>
        </div>

        <div className="skills_courses_wrapper">
          <div className="skills_courses">
            <h3 style={{ fontSize: "2em", fontWeight: 500 }}>
              My Skills & Education
            </h3>

            <p
              style={{
                marginBottom: "60px",
                color: "orange",
                fontSize: "1.2em",
              }}
            >
              Skills - Languages
            </p>

            <div className="skills-languages">
              <div className="skills">
                {skills.map((item, key) => {
                  return (
                    <Skill
                      logo={item.logo}
                      title={item.title}
                      description={item.description}
                      key={key}
                    />
                  );
                })}
              </div>

              <div className="separator"></div>

              <div className="languages">
                <ul className="languages-container">
                  {languages.map((item, key) => {
                    return (
                      <Language
                        onClick={handleShowDescription}
                        key={key}
                        name={item.name}
                        description={item.description}
                      />
                    );
                  })}
                </ul>
              </div>
            </div>

            <p style={{ margin: "60px 0", color: "orange", fontSize: "1.2em" }}>
              Education
            </p>

            <div className="courses">
              {courses.map((item, key) => {
                return (
                  <Course
                    graduate={item.graduate}
                    title={item.title}
                    where={item.where}
                    key={key}
                  />
                );
              })}
            </div>
          </div>
          {/* Deco */}
          <img
            src="/imgs/skills/skills-deco1.png"
            alt=""
            className="skills_deco1"
          />
          <img
            src="/imgs/skills/skills-deco2.png"
            alt=""
            className="skills_deco2"
          />
        </div>

        <div className="last-section-wrapper">
          <div>
            <br />
          </div>
          {/* <!-- Cards --> */}
          <div className="projects_meta_container">
            <div className="projects">
              <div id="projectsAncher" className="hwrapper">
                <h3 className="subtitle">Project Samples</h3>
              </div>
              <div className="elements_wrapper">
                {projects.map((item, key) => {
                  return (
                    <Card
                      title={item.title}
                      description={item.description}
                      link={item.link}
                      key={key}
                    />
                  );
                })}
              </div>
            </div>
          </div>

          <Social />

          <div className="roman-numerals">
            <div className="roman-content">
              <h4>
                Have you ever been in an emergency situation where you need to
                convert a number into a Roman Numeral?
              </h4>
              <p>
                You don't have the time to figure it out and it's a life or
                death situation!
              </p>
              <p>Let me guess. YES. We've all been there!</p>
              <p>
                Worry no more.{" "}
                <button
                  style={{ width: "auto", fontSize: "1em", color: "green" }}
                  onClick={handleOpen}
                >
                  Here's a solution
                </button>{" "}
                I coded for you.
              </p>
              <p>
                (Jokes aside, it's a cool algorithm I came up with
                :D)
              </p>
            </div>

            {isRomanPopupOpen ? <RomanNumerals /> : null}
          </div>
        </div>
      </main>

      <script
        type="text/javascript"
        src="http://code.jquery.com/jquery-1.4.2.min.js"
      ></script>

      <p id="email">vazquezbare@gmail.com</p>

      <Footer />
    </div>
  );
};

export default Home;
