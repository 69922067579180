export const projects = [
    {
        title: 'Web3 Website',
        description: 'Website for a Token IDO project',
        link: 'https://coinraise.xyz/'
    },
    {
        title: 'Reiki Website',
        description: 'Website for a Reiki practitioner',
        link: 'https://www.reikista.com/'
    },
    {
        title: 'Weather App',
        description: 'Sleek and minimal Weather app with API + Custom Hooks',
        link: 'https://elementalweath.web.app/'
    },
    {
        title: 'Wolves of DOGE Street',
        description: 'NFT Project (React App + Smart Contract)',
        link: 'https://www.wods.club/'
    },
    {
        title: 'Booby Birds NFT',
        description: 'NFT Project (React App + Smart Contract)',
        link: 'https://boobybirds.com/'
    },
    {
        title: 'Brandtrack',
        description: 'Music for Retail Businesses',
        link: 'https://www.brandtrack.ai/'
    },
    {
        title: 'Instagram Bot',
        description: 'IG Bot that likes posts with a certain hashtag',
        link: 'https://github.com/santheartist/IGBot'
    },
    {
        title: 'WEB3 Tools',
        description: 'Tools for NFT creators',
        link: 'https://web3tools.santiagovb.io/'
    },
]