export const skills = [
    {
        logo:'/imgs/skills/skills1.png',
        title:'Full Stack Developer',
        description:'4 Years of Experience. See list of languages',
    },
    {
        logo:'/imgs/skills/skills2.png',
        title:'Blockchain Developer',
        description:'#Solidity. Experience working mostly with Smart Contracts and the Minting Dapps.'
    },
    {
        logo:'/imgs/skills/skills3.png',
        title:'Prompt Engineering',
        description:'Always up to date with new technologies.',
    },
    {
        logo:'/imgs/skills/skills4.png',
        title:'Entertainment & Advertising',
        description:'+10 years of experience as Project Manager.',
    },
    {
        logo:'/imgs/skills/skills1.png',
        title:'Spoken Languages',
        description:'Fluent in English, Spanish and French + Basic level of Italian',
    },
    {
        logo:'/imgs/skills/skills6.png',
        title:'Strong Communication Skills',
        description:'One of my fortes: great link between Techies & Normies.',
    },
]