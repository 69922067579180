import './styles/App.scss';
import { Route, Routes } from 'react-router-dom';


// Pages
import Home from './pages/Home';
import Music from './pages/Music';

function App() {
  return (
    <div>

      <Routes>
        <Route path="/" exact={true} element={<Home />} />
        <Route path="/music" exact={true} element={<Music />} />
      </Routes>

    </div>
  );
}

export default App;
