import { createSlice } from '@reduxjs/toolkit'

export const romanPopupSlice = createSlice({
  name: 'romanPopup',
  initialState: {
    value: false
  },
  reducers: {
    showRomanPopup: (state) => {
      state.value = true
    },
    hideRomanPopup: (state) => {
      state.value = false
    }
  }
})

export const { showRomanPopup, hideRomanPopup } = romanPopupSlice.actions

export default romanPopupSlice.reducer
