import React from 'react'

const Music = () => {
  return (
    <div className='music-wrapper'>
            <div className="header_container">
                <h1>SVB</h1>
                <ul>
                    <li className="menu_item"><a href="/">Home</a></li>
                    <li className="menu_item"><a href="#ads">Ads</a></li>
                    <li className="menu_item"><a href="#about">About</a></li>
                    <li className="menu_item"><a href="#music">Music</a></li>
                </ul>
            </div>

            <div className="first_row">
                <div className="first_row_half frhl">
                    <h2>Music Composer & Producer</h2>
                    <div className="titles">
                        <p>· Bachelor of Music in Film Scoring</p>
                        <p>· Degree in Music Production</p>
                        <p>· Full Stack Developer (Web & Web3)</p>
                    </div>

                </div>
                <div className="first_row_half frhr">
                    <img className="profile_img" src="imgs/music/prof.jpg" alt="profile_img"/>
                    <div className="hidden_description">
                        <ul>
                            <li>Music for Films</li>
                            <li>Ads</li>
                            <li>Sound Design</li>
                            <li>Song Writing</li>
                            <li>String Arrangements</li>
                            <li>Project Management</li>
                            <li>Audio Engineering</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="second_row">
                <h3 id="ads">Latest Works</h3>
                <p>Playlist</p>
                <br/>
                <div className="video_player">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PLgUK4IyWLeDu_lWTuaxpCJFUIv7YVQF_j" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>

            <div className="third_row">
                <div className="about_container" id="about">
                    <h3>About Me</h3>
                    <div className="about_subcontainer">
                        <p className="about_p">Bachelor of Music in Film Scoring and Music Production. Multi-instrumentalist and vocalist. Hard working, passionate and inspired artist. Constantly re-inventing the way sound and light combine. When it comes to projects, I like to
                            find the perfect balance between solid advice with artistic guidance while respecting and empowering the client's idea.</p>
                        <img className="img2" src="imgs/music/IMG_9315_edited.jpg" alt="im2"/>
                    </div>
                </div>
            </div>

            <div className="fourth_row">
                <div className="music_container" id="music">
                    <a href="https://open.spotify.com/artist/53f7wcdGJ1vdbOzrW9o9SR?si=l7w0SLPfQc644H45r6aLdg">
                        Spotify 1 (Pop)
                        </a>
                    <a href="https://open.spotify.com/artist/3hZNKUqNDuVbzc05owhh5T?si=BNqfvPAjQr-1BS5lNtTJIg">
                        Spotify 2 (Classical/Chill Hop)
                        </a>
                </div>
            </div>

        {/* <!-- Footer --> */}
            <div className="footer_container">
                <ul className="footer_ul">
                    <li>Contact </li>
                </ul>
            </div>

        <script src="js/index.js"></script>
    </div>
  )
}

export default Music;