import React from "react";

function Card(props) {
  return (
    <div className="card_wrapper">
      <div className="card">
        <div className="card-content">
          <h2 className="card-title">{props.title}</h2>
        </div>
      </div>

      <div className="card-below">
        <p className="card-body">{props.description}</p>
        <a href={props.link} target="blank" rel="noreferrer" className="button">
          Learn More
        </a>
      </div>
    </div>
  );
}

export default Card;
