import React from 'react';

const Social = () => {
  return (
    <div className='social-wrapper'>
        <h3 className='subtitle'>Social Links</h3>
        <div className="social-links">
            <a href="https://twitter.com/blockchain_add" target='_blank' rel="noreferrer"><img src="/imgs/social/tweeter.png" alt="" /></a> 
            <a href="https://github.com/blockchainaddict" target='_blank' rel="noreferrer"><img src="/imgs/social/git.png" alt="" /></a> 
            <a href="https://www.instagram.com/sanvb.music/" target='_blank' rel="noreferrer"><img src="/imgs/social/ig.png" alt="" /></a> 
        </div>
    </div>
  )
}

export default Social