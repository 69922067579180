import React from 'react'

const Footer = () => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText("vazquezbare@gmail.com");
      };

  return (
    <div className="footer_container">
        <div className="footer_links">
          <div className="footer_links_wrapper">
            <div className="footer_link--items">
              <h4>Contact & Links</h4>
              {/* <!-- <a href="contact">Email (click to copy)</a> --> */}
              <button onClick={copyToClipboard} id="buttonCopy">
                Email (click to copy)
              </button>
              {/* <a href="a" id="email_invisible" target="_blank"></a> */}
              <a
                href="https://www.linkedin.com/in/santiagovb/"
                target="_blank"
                rel="noreferrer"
              >
                Linkedin
              </a>
              <a
                href="https://github.com/santheartist"
                target="_blank"
                rel="noreferrer"
              >
                GitHub
              </a>
            </div>

            <div className="footer_link--items">
              <h4>Social</h4>
              
              <a
                href="https://www.instagram.com/sanvb.music/"
                target="_blank"
                rel="noreferrer"
              >
                IG
              </a>
              <a
                href="https://twitter.com/blockchain_add"
                target="_blank"
                rel="noreferrer"
              >
                Twitter
              </a>
            </div>

            <div className="footer_link--items">
              <h4>More</h4>
              
              <a
                href="https://www.fiverr.com/jacdebaires?public_mode=true"
                target="_blank"
                rel="noreferrer"
              >
                Fiverr
              </a>
              <a
                href="https://blockchainaddict.wordpress.com/"
                target="_blank"
                rel="noreferrer"
              >
                Blog 👻
              </a>
            </div>

            <div className="footer_link--items">
              <h4>Ressources</h4>
              <a
                href="https://www.digitalhouse.com/"
                target="_blank"
                rel="noreferrer"
              >
                DigitalHouse
              </a>
              <a
                href="https://www.w3schools.com/default.asp"
                target="_blank"
                rel="noreferrer"
              >
                W3Schools
              </a>
              <a
                href="https://www.coursera.org/learn/python"
                target="_blank"
                rel="noreferrer"
              >
                Coursera (Python)
              </a>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Footer
