import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate, faUserGraduate } from '@fortawesome/free-solid-svg-icons'; 

function Course (props){
  return (
    <div className='course_wrapper'>
        {/* <img className='logo' src={props.logo} alt="" /> */}
        { props.graduate === true ? <FontAwesomeIcon icon={faUserGraduate} /> : <FontAwesomeIcon icon={faCertificate} /> }                
        <h3>{props.title}</h3>
        <p>From: <span>{props.where}</span></p>
    </div>
  )
}

export default Course;
