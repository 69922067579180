function Language(item) {
  return (
    <div className="language-wrapper">

      <div className="language-component">
          <li >{item.name}</li>
          <img style={{width: "50px", height: "auto"}} src={`/imgs/languages/${item.name}.png`} alt="" />
      </div>
      
      <div className="language-description-wrapper">
          <p className="language-description">{item.description}</p>
      </div>
    </div>
  );
}

export default Language;
