import React from 'react';

function Skill (props){
  return (
    <div className='skill_wrapper' title={props.title}>
        <img className='logo' src={props.logo} alt="" />
        <h3>{props.title}</h3>
        <p className='skill-description'>{props.description}</p>
    </div>
  )
}

export default Skill;